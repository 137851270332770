<template>
	<div class="relative flex flex-col gap-2" :style="cssProps">
		<div class="relative my-1">
			<span
				ref="activeRange"
				class="absolute bottom-0 top-0 m-auto h-1 w-[99%] rounded"
			></span>
			<input
				v-model.number="maxSelection"
				type="range"
				step="0.01"
				:min="min"
				:max="max"
				:aria-label="aria"
				class="slider"
			/>
		</div>
		<div class="row flex justify-between text-xs md:text-base">
			<span>{{ minIndicator }}</span>
			<span>{{ maxIndicator }}</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import { onMounted, watch, ref, computed, watchEffect } from 'vue';
const maxSelectionPercentage = ref(100);
const activeRange = ref<HTMLSpanElement | null>(null);

interface Props {
	min: number;
	max: number;
	selection: number | null;
	unit: string;
	unitSuffix: boolean;
	fontSize?: string;
	aria: string;
}

const props = defineProps<Props>();

const emit = defineEmits(['changeOption']);

const maxSelection = computed({
	get: () => props.selection,
	set: (value) => {
		emit('changeOption', value);
	},
});

const maxIndicator = computed(() => {
	return props.unitSuffix
		? `${maxSelection.value}${props.unit}`
		: `${props.unit}${maxSelection.value}`;
});

const minIndicator = computed(() => {
	return props.unitSuffix ? `${props.min}${props.unit}` : `${props.unit}${props.min}`;
});

const cssProps = computed(() => {
	return {
		'--font-size': props.fontSize,
	};
});

watchEffect(() => {
	if (maxSelection.value !== null) {
		maxSelectionPercentage.value =
			((maxSelection.value - props.min) * 100) / (props.max - props.min);
	}
});

// Set bar color
watch(maxSelectionPercentage, (newMaxSelectionPercentage) => {
	if (activeRange.value) {
		activeRange.value.style.background = `linear-gradient(to right, #dadae5 0% , #00664b 0% , #00664b ${newMaxSelectionPercentage}%, #dadae5 ${newMaxSelectionPercentage}%)`;
	}
});

onMounted(() => {
	if (activeRange.value) {
		activeRange.value.style.background = `linear-gradient(to right, #dadae5 0% , #00664b 0% , #00664b 100%, #dadae5 100%)`;
	}
});
</script>
